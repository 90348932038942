<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item :label="$t('销售订单.订单类型,20:普通订单,50:退款订单')" prop="type" >
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.父订单id,order_order.id')" prop="parentId" >
        <a-input v-model="form.parentId" :placeholder="$t('通用.输入.请输入')+$t('销售订单.父订单id,order_order.id')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.三方支付订单号')" prop="outTradeNo" >
        <a-input v-model="form.outTradeNo" :placeholder="$t('通用.输入.请输入')+$t('销售订单.三方支付订单号')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.卖方客户id')" prop="shopId" >
        <a-input v-model="form.shopId" :placeholder="$t('通用.输入.请输入')+$t('销售订单.卖方客户id')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.卖方客户名字')" prop="shopName" >
        <a-input v-model="form.shopName" :placeholder="$t('通用.输入.请输入')+$t('销售订单.卖方客户名字')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.买方客户id')" prop="buyerId" >
        <a-input v-model="form.buyerId" :placeholder="$t('通用.输入.请输入')+$t('销售订单.买方客户id')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.买方客户名称')" prop="buyerName" >
        <a-input v-model="form.buyerName" :placeholder="$t('通用.输入.请输入')+$t('销售订单.买方客户名称')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.用户备注')" prop="userMessage" >
        <a-input v-model="form.userMessage" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.订单收货信息JSON')" prop="addressDetail" >
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.活动信息json')" prop="activityDetail" >
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.下单客户id，标识是否代客下单')" prop="orderUserId" >
        <a-input v-model="form.orderUserId" :placeholder="$t('通用.输入.请输入')+$t('销售订单.下单客户id，标识是否代客下单')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.支付方式 1,支付宝 2微信,3银行卡')" prop="payType" >
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.支付时间')" prop="payTime" >
        <a-date-picker style="width: 100%" v-model="form.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.订单状态')" prop="orderStatus" >
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.订单退款状态0:未申请 1.买家申请 2.卖家接受 3.买家发货 4.卖家收货 5.退款成功 6卖家拒绝')" prop="refundStatus" >
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.商品数量')" prop="goodsCount" >
        <a-input v-model="form.goodsCount" :placeholder="$t('通用.输入.请输入')+$t('销售订单.商品数量')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.总订单价格(原价)')" prop="orderAmount" >
        <a-input v-model="form.orderAmount" :placeholder="$t('通用.输入.请输入')+$t('销售订单.总订单价格(原价)')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.总订单价格(实付) ')" prop="payAmount" >
        <a-input v-model="form.payAmount" :placeholder="$t('通用.输入.请输入')+$t('销售订单.总订单价格(实付) ')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.订单包装费')" prop="packingAmount" >
        <a-input v-model="form.packingAmount" :placeholder="$t('通用.输入.请输入')+$t('销售订单.订单包装费')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.订单运费(实付)')" prop="shippingPayAmount" >
        <a-input v-model="form.shippingPayAmount" :placeholder="$t('通用.输入.请输入')+$t('销售订单.订单运费(实付)')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.订单运费(原价)')" prop="shippingAmount" >
        <a-input v-model="form.shippingAmount" :placeholder="$t('通用.输入.请输入')+$t('销售订单.订单运费(原价)')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.余额支付金额')" prop="balanceAmount" >
        <a-input v-model="form.balanceAmount" :placeholder="$t('通用.输入.请输入')+$t('销售订单.余额支付金额')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.三方支付金额')" prop="outTradeAmount" >
        <a-input v-model="form.outTradeAmount" :placeholder="$t('通用.输入.请输入')+$t('销售订单.三方支付金额')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.是否首单 0:否 1:是')" prop="isFirstOrder" >
        <a-input v-model="form.isFirstOrder" :placeholder="$t('通用.输入.请输入')+$t('销售订单.是否首单 0:否 1:是')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.顾客是否催发货 0-否 1-是')" prop="isRemind" >
        <a-input v-model="form.isRemind" :placeholder="$t('通用.输入.请输入')+$t('销售订单.顾客是否催发货 0-否 1-是')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.显示标记，1：显示，0：不显示，默认1显示')" prop="isDisplay" >
        <a-input v-model="form.isDisplay" :placeholder="$t('通用.输入.请输入')+$t('销售订单.显示标记，1：显示，0：不显示，默认1显示')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('销售订单.后台备注')" prop="adminRemark" >
        <a-input v-model="form.adminRemark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSell, addSell, updateSell } from '@/api/point/sell'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        parentId: null,

        outTradeNo: null,

        shopId: null,

        shopName: null,

        buyerId: null,

        buyerName: null,

        userMessage: null,

        addressDetail: null,

        activityDetail: null,

        orderUserId: null,

        payType: null,

        payTime: null,

        orderStatus: 0,

        refundStatus: 0,

        goodsCount: null,

        orderAmount: null,

        payAmount: null,

        packingAmount: null,

        shippingPayAmount: null,

        shippingAmount: null,

        balanceAmount: null,

        outTradeAmount: null,

        isFirstOrder: null,

        isRemind: null,

        isDisplay: null,

        createTime: null,

        adminRemark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: this.$t('销售订单.订单类型,20:普通订单,50:退款订单')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        parentId: [
          { required: true, message: this.$t('销售订单.父订单id,order_order.id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        shopId: [
          { required: true, message: this.$t('销售订单.卖方客户id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        shopName: [
          { required: true, message: this.$t('销售订单.卖方客户名字')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        buyerId: [
          { required: true, message: this.$t('销售订单.买方客户id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        buyerName: [
          { required: true, message: this.$t('销售订单.买方客户名称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        addressDetail: [
          { required: true, message: this.$t('销售订单.订单收货信息JSON')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        orderUserId: [
          { required: true, message: this.$t('销售订单.下单客户id，标识是否代客下单')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        orderStatus: [
          { required: true, message: this.$t('销售订单.订单状态')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        goodsCount: [
          { required: true, message: this.$t('销售订单.商品数量')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        orderAmount: [
          { required: true, message: this.$t('销售订单.总订单价格(原价)')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        payAmount: [
          { required: true, message: this.$t('销售订单.总订单价格(实付) ')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        packingAmount: [
          { required: true, message: this.$t('销售订单.订单包装费')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        shippingPayAmount: [
          { required: true, message: this.$t('销售订单.订单运费(实付)')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        balanceAmount: [
          { required: true, message: this.$t('销售订单.余额支付金额')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        outTradeAmount: [
          { required: true, message: this.$t('销售订单.三方支付金额')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isRemind: [
          { required: true, message: this.$t('销售订单.顾客是否催发货 0-否 1-是')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isDisplay: [
          { required: true, message: this.$t('销售订单.显示标记，1：显示，0：不显示，默认1显示')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        parentId: null,
        outTradeNo: null,
        shopId: null,
        shopName: null,
        buyerId: null,
        buyerName: null,
        userMessage: null,
        addressDetail: null,
        activityDetail: null,
        orderUserId: null,
        payType: null,
        payTime: null,
        orderStatus: 0,
        refundStatus: 0,
        goodsCount: null,
        orderAmount: null,
        payAmount: null,
        packingAmount: null,
        shippingPayAmount: null,
        shippingAmount: null,
        balanceAmount: null,
        outTradeAmount: null,
        isFirstOrder: null,
        isRemind: null,
        isDisplay: null,
        createTime: null,
        adminRemark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSell({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateSell(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addSell(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
